
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'pinia';
import useFormsPages from '@/stores/forms/pages';
import Loader from '../components/loader.vue';
import ToolbarActions from '../components/table/toolbar-actions.vue';
import DataTable from '../components/table/data-table.vue';
import NameColumn from '../components/table/name-column.vue';
import StatusColumn from '../components/table/status-column.vue';
import {
  Page, PageRow, PageStatus, PageColumn, PageAction,
} from './types';

@Component({
  components: {
    DataTable,
    ToolbarActions,
    Loader,
    'share-form': () => import('./pages-share-modal.vue'),
  },
  computed: {
    ...mapState(useFormsPages, ['pages']),
  },
})
export default class PagesList extends Vue {
  pages: Page[];

  private isTableLoading = true;

  private selectedIds: string[] = [];

  private sharePageId = '';

  private formsPages = useFormsPages();

  get tableData(): PageRow[] {
    return this.pages.map((page) => {
      const row: PageRow = {
        id: page.id,
        get name() {
          return {
            title: page.title,
            link: {
              name: 'Forms_Pages_Editor',
              params: { pageId: row.id },
            },
          };
        },
        status: page.isDefault ? PageStatus.Default : '',
        delete: async (confirmationDisabled = false) => {
          if (!confirmationDisabled && !this.confirmDeletion(row.name.title)) {
            return;
          }

          await this.formsPages.deletePage(page.id);
          this.selectedIds = this.selectedIds.filter((id) => id !== page.id);
        },
        edit: () => {
          this.$router.push({ name: 'Forms_Pages_Editor', params: { pageId: row.id } });
        },
        share: () => {
          this.sharePageId = page.id;
          this.$modal.show('share-form');
        },
        setDefault: async () => {
          await this.formsPages.setDefaultPage(row.id);
        },
      };

      return row;
    });
  }

  get toolbarActions(): PageAction[] {
    if (!this.selectedRows.length) return [];

    const toolbarActions: PageAction[] = [
      {
        id: '0',
        icon: ['fal', 'share-from-square'],
        title: 'Share',
        isMultiSelected: false,
        visible: true,
        handler: () => {
          this.selectedRows[0].share();
        },
      },
      {
        id: '1',
        icon: ['fal', 'pencil'],
        title: 'Edit',
        isMultiSelected: false,
        visible: true,
        handler: () => {
          this.selectedRows[0].edit();
        },
      },
      {
        id: '2',
        icon: ['fal', 'file-check'],
        title: 'Set default',
        isMultiSelected: false,
        visible: this.selectedRows[0].status !== PageStatus.Default,
        handler: () => {
          this.selectedRows[0].setDefault();
        },
      },
      {
        id: '3',
        icon: ['fal', 'trash-can'],
        title: 'Delete',
        isMultiSelected: true,
        visible: true,
        handler: async () => {
          const pageName = this.selectedIds.length <= 1 && this.selectedRows[0].name.title;

          if (!this.confirmDeletion(pageName)) return;

          await Promise.all(this.selectedRows.map((row) => row.delete(true)));
          this.selectedIds = [];
        },
      },
    ];

    if (this.selectedRows.length === 1) return toolbarActions;
    return toolbarActions.filter((action: PageAction) => action.isMultiSelected);
  }

  get PageStatus() {
    return PageStatus;
  }

  get columns(): PageColumn[] {
    return [
      {
        id: '0',
        component: NameColumn,
        field: 'name',
        label: 'Name',
        actions: [
          {
            id: '0',
            icon: ['fal', 'share-from-square'],
            handler(row: PageRow) {
              row.share();
            },
            text: 'Share',
            visible() {
              return true;
            },
          },
          {
            id: '1',
            icon: ['fal', 'pencil'],
            handler(row) {
              row.edit();
            },
            text: 'Edit',
            visible() {
              return true;
            },
          },
        ],
        contextMenuActions: [
          {
            id: '0',
            icon: ['fal', 'file-check'],
            async handler(row: PageRow) {
              await row.setDefault();
            },
            text: 'Set default',
            visible(row: PageRow) {
              return row.status !== PageStatus.Default;
            },
          },
          {
            id: '1',
            icon: ['fal', 'trash-can'],
            async handler(row: PageRow) {
              await row.delete();
            },
            text: 'Delete',
            visible() {
              return true;
            },
          },
        ],
        customSort: (a: PageRow, b: PageRow, isAsc: boolean) => {
          if (isAsc) return a.name.title.localeCompare(b.name.title);
          return b.name.title.localeCompare(a.name.title);
        },
      },
      {
        id: '1',
        component: StatusColumn,
        field: 'status',
        label: '',
        customSort: (a: PageRow, b: PageRow, isAsc: boolean) => {
          if (isAsc) return a.status.localeCompare(b.status);
          return b.status.localeCompare(a.status);
        },
      },
    ];
  }

  private confirmDeletion(pageName: string | false = false) {
    if (!pageName) {
      return window.confirm('Are you sure you want to delete the selected pages?');
    }
    return window.confirm(`Are you sure you want to delete the page "${pageName}"?`);
  }

  private selectionChanged(value: string[]) {
    this.selectedIds = value;
  }

  private hideShareModal() {
    this.$modal.hide('share-form');
    this.sharePageId = '';
  }

  async mounted() {
    try {
      await this.formsPages.loadPages();
    } catch (error) {
      throw new Error("Can't load pages list");
    }
    this.isTableLoading = false;
  }

  get selectedRows(): PageRow[] {
    return this.tableData.filter((f) => this.selectedIds.indexOf(f.id) !== -1);
  }
}
