<template>
  <router-view v-if="pageId" />
  <pages-list v-else />
</template>

<script>
import PagesList from './pages-list.vue';

export default {
  name: 'PagesPage',
  components: { PagesList },
  computed: {
    pageId() {
      return this.$route.params.pageId;
    }
  }
};
</script>
