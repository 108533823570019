import { render, staticRenderFns } from "./pages-list.vue?vue&type=template&id=0d432f42&scoped=true&"
import script from "./pages-list.vue?vue&type=script&lang=ts&"
export * from "./pages-list.vue?vue&type=script&lang=ts&"
import style0 from "./pages-list.vue?vue&type=style&index=0&id=0d432f42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d432f42",
  null
  
)

export default component.exports