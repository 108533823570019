import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';
import { Page } from '@/views/forms/pages/types';
import { formsApiEndpoint } from '@/utils/url-manager.js';

const formsEndpoint = formsApiEndpoint;

export default defineStore('formsPages', () => {
  const pages = ref<Page[]>([]);

  async function deletePage(id: string) {
    await axios.delete(`${formsEndpoint}pages/${id}`);
    pages.value = pages.value.filter((p) => p.id !== id);
  }

  async function loadPages() {
    const newPages = (await axios.get<Page[]>(`${formsEndpoint}pages`)).data;
    pages.value = newPages;
  }

  async function getPageSettings(id: string) {
    return (await axios.get<Page>(`${formsEndpoint}pages/${id}`)).data;
  }

  async function createPage(page: Page) {
    const { id } = (await axios.post(`${formsEndpoint}pages`, page)).data;
    await loadPages();
    return id;
  }

  async function updatePage(page: Page) {
    return (await axios.put(`${formsEndpoint}pages/${page.id}`, page)).data;
  }

  async function setDefaultPage(id: string) {
    await updatePage({
      id,
      isDefault: true,
    } as Page);
    await loadPages();
  }

  return {
    pages,
    deletePage,
    loadPages,
    getPageSettings,
    createPage,
    updatePage,
    setDefaultPage,
  };
});
