import {
  Row, RowAction, Column, Action,
} from '../components/table/types';

export enum PageStatus {
  Default = 'Default',
}

export interface Settings {
  bodyBackground: string;
  bodyImage: string | null;
  footerBackground: string;
  footerText: string;
  footerTextColor: string;
  headerBackground: string;
  headerPattern: string;
  logoImage: string;
  logoLink: string;
  pageTitle: string | null;
  textAbove: string;
}
export interface Page {
  id: string ;
  title: string ;
  isDefault: boolean;
  settings: Settings;
}

interface PageName {
  title: string;
  link: {
    name: string;
    params: {
      pageId: string;
    };
  };
}

export interface PageRow extends Row {
  id: string;
  name: PageName;
  status: string;
  delete: (confirmationDisabled?: boolean) => Promise<void>;
  edit: () => void;
  share: () => void;
  setDefault: () => void;
}

export interface PageRowAction extends RowAction {
  handler: (row: PageRow) => void;
  visible: (row: PageRow) => boolean;
}

export interface PageColumn extends Column {
  actions?: PageRowAction[];
  contextMenuActions?: PageRowAction[];
  customSort?: (a: PageRow, b: PageRow, isAsc: boolean) => number;
}
export type PageAction = Action;

export interface rteConfig {
  editSource: boolean;
  buttons: string[];
  minHeight: number;
}

export interface ImagePattern {
  name: string;
  downloadUrl: string | null;
}

export interface ErrorResponse {
  response: {
    status: number;
    data: {
      error: string;
    };
  };
}
